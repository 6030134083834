'use client';

import type {
    RecipeSearchFilterFragment,
    RecipeSearchQueryFilter,
} from '@graphql/_generated-operation-types';
import { updateFilters } from '@helpers/filters';
import {
    type RecipeSearchSortbySortOption,
    type SearchQuery,
    searchQueryToUrl,
    urlToSearchQuery,
} from '@helpers/search';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

export interface UseSearch {
    page: number;
    query?: string;
    sortBy?: RecipeSearchSortbySortOption;
    filters: RecipeSearchQueryFilter[];
    setSearchText: (searchText: string) => void;
    setSorting: (sortBy: RecipeSearchSortbySortOption) => void;
    setFilter: (filter: RecipeSearchFilterFragment) => void;
    setFilters: (filter: RecipeSearchFilterFragment[]) => void;
    setPage: (page: number) => void;
    resetFilters: () => void;
}

export enum QueryUpdateType {
    REPLACE = 'replace',
    PUSH = 'push',
}

export const useSearch = (): UseSearch => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const router = useRouter();
    const params = useMemo(
        () => urlToSearchQuery(searchParams),
        [searchParams],
    );

    const updateQuery = (
        newParams: SearchQuery,
        updateType: QueryUpdateType = QueryUpdateType.REPLACE,
    ) => {
        const query = searchQueryToUrl(newParams);
        const path = `${pathname}${query}`;
        if (updateType === QueryUpdateType.PUSH) {
            router.push(path, { scroll: false });
        } else {
            router.replace(path, { scroll: false });
        }
    };

    const setPage = (newPage: number) => {
        const { page, ...rest } = params;
        const newParams: SearchQuery =
            newPage !== 1 ? { ...rest, page: newPage } : rest;

        updateQuery(newParams, QueryUpdateType.PUSH);
    };

    const setFilter = (changedFilter: RecipeSearchFilterFragment) => {
        const { page, filters, ...rest } = params;
        const newFilters = updateFilters(filters, [changedFilter]);
        const newParams: SearchQuery = {
            ...rest,
            filters: newFilters,
        };

        updateQuery(newParams);
    };

    const setFilters = (changedFilter: RecipeSearchFilterFragment[]) => {
        const { page, ...rest } = params;
        const newFilters = updateFilters(params.filters, changedFilter);
        const newParams: SearchQuery = {
            ...rest,
            filters: newFilters,
        };

        updateQuery(newParams);
    };

    const setSorting = (sortBy: RecipeSearchSortbySortOption) => {
        const { page, ...rest } = params;
        const newParams: SearchQuery = {
            ...rest,
            sortBy,
        };

        updateQuery(newParams);
    };

    const setSearchText = (searchText: string) => {
        const { query, page, ...rest } = params;
        const newParams: SearchQuery = searchText.length
            ? {
                  ...rest,
                  query: searchText,
              }
            : rest;

        updateQuery(newParams);
    };

    const resetFilters = () => {
        const { page, ...rest } = params;
        const newParams: SearchQuery = {
            ...rest,
            filters: [],
        };

        updateQuery(newParams);
    };

    return {
        query: params.query,
        sortBy: params.sortBy,
        page: params.page || 0,
        filters: params.filters,
        setSorting,
        setFilter,
        setFilters,
        setPage,
        setSearchText,
        resetFilters,
    };
};
