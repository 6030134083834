'use client';

import { useContext } from 'react';
import type { ViewportData } from '../types';
import { ViewportContext } from './context';

export const useViewport = (): ViewportData => {
    const context = useContext(ViewportContext);

    if (context === null) {
        throw new Error('useViewport must be used within a ViewportProvider');
    }

    return context;
};
