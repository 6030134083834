'use client';

import { Viewport } from '@royalaholddelhaize/ah-next-core/src/enums/viewport';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { useViewport } from '@royalaholddelhaize/ah-next-core/src/viewport/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_PAGINATION } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import {
    Pagination as PaginationComp,
    PaginationContent,
    PaginationItem,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/pagination/pagination';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import {
    PaginationAnchor,
    PaginationEllipsis,
    PaginationNextButton,
    PaginationPreviousButton,
} from './elements';

import css from './pagination.module.scss';

export interface PaginationProps {
    onPagination: (page: number) => void;
    currentPage: number;
    totalPages: number;
}

/**
 * Always display
 * - The first page
 * - The last pag
 * - The previous & next page (unless under 0 or above total pages)
 * - 3 pages next to each other around the current page
 *
 * 1 2 3 … 57
 * 1 2 3 … 57
 * 1 2 3 4 … 57
 * 1 … 3 4 5 … 57
 * 1 … 53 54 55 … 57
 * 1 … 54 55 56 57
 * 1 … 55 56 57
 * 1 … 55 56 57
 */
const PageAnchors: FC<PaginationProps> = ({
    totalPages,
    currentPage,
    onPagination,
}) => {
    const { t } = useTranslations();

    const items = [...Array(totalPages || 0)].map((_, index) => {
        const page = index + 1;
        const isActive = page === currentPage;
        const isFirst = page === 1;
        const isLast = page === totalPages;

        const handlePagination = () => {
            onPagination(page);
        };

        const defaultProps = {
            className: classNames(
                css.pageAnchor,
                isActive && css.pageAnchorActive,
            ),
            onClick: handlePagination,
            isActive,
            page,
            'aria-label': t('pagination.page', { page }),
        };

        if (isFirst) {
            return (
                <PaginationItem key={`pagination-${page}`}>
                    <PaginationAnchor {...defaultProps} />

                    {currentPage > 3 && totalPages > 4 && (
                        <PaginationEllipsis className={css.ellipsis} />
                    )}
                </PaginationItem>
            );
        }

        if (isLast) {
            return (
                <PaginationItem key={`pagination-${page}`}>
                    {currentPage < totalPages - 2 && totalPages > 4 && (
                        <PaginationEllipsis className={css.ellipsis} />
                    )}

                    <PaginationAnchor {...defaultProps} />
                </PaginationItem>
            );
        }

        if (
            page === currentPage - 1 ||
            (currentPage === totalPages && page === currentPage - 2) ||
            page === currentPage ||
            page === currentPage + 1 ||
            (currentPage === 1 && page === currentPage + 2)
        ) {
            return (
                <PaginationItem key={`pagination-${page}`}>
                    <PaginationAnchor {...defaultProps} />
                </PaginationItem>
            );
        }

        // Skips additional pagination anchors, we don't want to render these
        return false;
    });

    return <>{items}</>;
};

export const Pagination: FC<PaginationProps & ComponentProps<'nav'>> = ({
    onPagination,
    currentPage,
    totalPages,
    ...restProps
}) => {
    const { viewport } = useViewport();
    const isMobile = [
        Viewport.EXTRA_SMALL,
        Viewport.SMALL,
        Viewport.MEDIUM,
    ].includes(viewport);
    const { t } = useTranslations();

    const isPreviousDisabled = currentPage - 1 <= 0;
    const isNextDisabled = currentPage + 1 > totalPages;

    const handleOnPreviousPage = () => {
        if (isPreviousDisabled) {
            return;
        }

        onPagination(currentPage - 1);
    };

    const handleOnNextPage = () => {
        if (isNextDisabled) {
            return;
        }

        onPagination(currentPage + 1);
    };

    if (totalPages <= 1) {
        return null;
    }

    return (
        <PaginationComp
            {...restProps}
            className={css.root}
            {...createTestHook(SEARCH_PAGINATION)}
        >
            <PaginationContent>
                <PaginationItem>
                    <PaginationPreviousButton
                        onClick={handleOnPreviousPage}
                        className={css.button}
                        disabled={isPreviousDisabled}
                        aria-label={t('pagination.previous')}
                    />
                </PaginationItem>

                {isMobile ? (
                    <PaginationItem className={css.pageSummary}>
                        <Typography variant="body-regular" as="span">{`pagina ${
                            currentPage
                        } van ${totalPages}`}</Typography>
                    </PaginationItem>
                ) : (
                    <PageAnchors
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPagination={onPagination}
                    />
                )}

                <PaginationItem>
                    <PaginationNextButton
                        onClick={handleOnNextPage}
                        className={css.button}
                        disabled={isNextDisabled}
                        aria-label={t('pagination.next')}
                    />
                </PaginationItem>
            </PaginationContent>
        </PaginationComp>
    );
};
