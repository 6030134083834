import type { ComponentProps, FC } from 'react';

export const VeganIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <title>Vegan Icon</title>
        <path d="M22.948 9.658a1.284 1.284 0 00-1.821-1.144l-3.719 1.717a3.7 3.7 0 00-1.828 2.33 3.846 3.846 0 00.345 2.8 13.144 13.144 0 00-1.742 1.818 12.46 12.46 0 00-.7-1.64c-.2-.389-.42-.758-.642-1.117a6.718 6.718 0 001.134-5.9 6.5 6.5 0 00-3.337-4.054L3.2 1.189A1.574 1.574 0 00.987 2.628L.982 10.8v.074a6.506 6.506 0 001.034 3.113 6.635 6.635 0 004.758 2.966 6.5 6.5 0 00.81.05 6.571 6.571 0 001.923-.291 18.551 18.551 0 00-1.221-1.777 4.506 4.506 0 01-1.267.034 4.644 4.644 0 01-3.328-2.074 4.458 4.458 0 01-.712-2.132V4.82a1 1 0 011.4-.915L9.764 6.27a4.452 4.452 0 012.286 2.8 4.714 4.714 0 01.162 1.635 1.007 1.007 0 01-1.759.547 58.456 58.456 0 00-1.55-1.68l-.986-1.04-1.452 1.376.988 1.043c.135.142.277.295.417.445l.051.054c.09.1.177.19.268.289a23.025 23.025 0 013.4 4.524c.041.068.085.132.123.2.018.036.031.076.049.112.048.091.1.176.147.269a11.905 11.905 0 011.077 4.806V22a1 1 0 002 0v-1.967c0-.778.965-2 2.084-3.007a.5.5 0 01.555-.064 3.757 3.757 0 001.621.371 3.976 3.976 0 00.953-.12 3.766 3.766 0 002.821-3.442v-.038zm-2.188 4.835a1.791 1.791 0 01-3.246-1.421 1.683 1.683 0 01.8-1.057l1.96-.9a.5.5 0 01.709.445l.037 2.134a1.7 1.7 0 01-.26.799z" />
    </svg>
);
