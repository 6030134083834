import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { TypographyProps } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography.interfaces';
import classNames from 'clsx';
import type { FC } from 'react';

import css from './card-text.module.scss';

export const CardText: FC<TypographyProps> = ({ className, ...rest }) => (
    <Typography
        variant="body-strong"
        className={classNames(css.text, className)}
        {...rest}
    />
);
