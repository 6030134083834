import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';

import { HeartIcon } from '../../../../../assets/heart';
import css from './card-image.module.scss';

type CardImageProps = ComponentProps<'img'>;

export const CardImage: FC<CardImageProps> = ({
    className,
    srcSet,
    ...restProps
}) => {
    if (!srcSet) {
        return (
            <div className={classNames(css.empty, className)}>
                <HeartIcon />
            </div>
        );
    }

    return (
        // biome-ignore lint/a11y/useAltText: <explanation>
        <img
            className={classNames(css.image, className)}
            srcSet={srcSet}
            loading="lazy"
            {...restProps}
        />
    );
};
