import { Button, type ButtonProps } from '@components/elements/button/button';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_PAGINATION_NEXT } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { ChevronRight16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-right-16';
import type { FC } from 'react';

export const PaginationNextButton: FC<ButtonProps> = props => {
    return (
        <Button
            {...createTestHook(SEARCH_PAGINATION_NEXT)}
            {...props}
            variant="secondary"
        >
            <ChevronRight16Icon size={12} />
        </Button>
    );
};
