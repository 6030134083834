import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';

import css from './card-floating-icon-cta.module.scss';

type CardFloatingIconCTAProps = ComponentProps<'button'> & {
    position?: 'top-left' | 'top-right';
    selected?: boolean;
};

export const CardFloatingIconCTA: FC<CardFloatingIconCTAProps> = ({
    position = 'top-right',
    selected = false,
    className,
    ...restProps
}) => {
    const classes = classNames(
        css.cta,
        css[position],
        selected && css.selected,
    );

    return <button className={classes} type="button" {...restProps} />;
};
