import { RECIPE_SEARCH_PAGE_SIZE } from '@constants/search';
import type {
    RecipeSearchQueryFilter,
    RecipeSearchSortOption,
    RecipeVideoSearchSortOption,
} from '@graphql/_generated-operation-types';
import { getQueryFromSearch, queryToSearchString } from '@helpers/url';
import type { SearchParams } from '@royalaholddelhaize/ah-next-core/src/interfaces/search-params';
import type { ReadonlyURLSearchParams } from 'next/navigation';

export type SearchQueryFilters = Record<string, string | string[]>;

export type RecipeSearchSortbySortOption =
    | RecipeSearchSortOption
    | RecipeVideoSearchSortOption;

export interface SearchQuery {
    sortBy?: RecipeSearchSortbySortOption;
    query?: string;
    page?: number | null;
    filters: RecipeSearchQueryFilter[];
}

export type SortingChangeValueType = {
    name: string;
    value: RecipeSearchSortbySortOption;
};

const ALLOWED_FILTER_NAMES = [
    'veel-gebruikt',
    'menugang',
    'nutri-score',
    'soort-gerecht',
    'recepten-met',
    'speciale-wensen',
    'momenten',
    'seizoen',
    'kooktechniek',
    'keuken',
    'allerhande-magazine',
    'soort-video',
    'tags',
];

export const searchQueryToUrl = ({
    filters,
    page,
    query,
    sortBy,
}: SearchQuery): string => {
    const searchQueryFilters = filters.reduce((acc, filter) => {
        return {
            ...acc,
            [filter.group]: filter.values,
        };
    }, {} as SearchQueryFilters);
    const flatQuery = {
        ...searchQueryFilters,
        page,
        sortBy,
        query,
    };
    return queryToSearchString(flatQuery);
};

export const sanitizeSearchParams = (searchParams: SearchParams) => {
    const { page, sortBy, query, ...searchQueryFilters } = searchParams;

    const filters = Object.entries(searchQueryFilters)
        .map(([group, key]): RecipeSearchQueryFilter => {
            if (!key) {
                return {
                    group,
                    values: [],
                };
            }
            return {
                group,
                // URI.js gives string or string[] depending on how many query parameters with the same name
                values: Array.isArray(key) ? key : [key],
            };
        })
        .filter(
            filterGroup =>
                ALLOWED_FILTER_NAMES.includes(filterGroup.group) &&
                filterGroup.values.length,
        );

    return {
        filters,
        sortBy: [sortBy].flat()[0] as SearchQuery['sortBy'],
        query: [query].flat()[0] as SearchQuery['query'],
        page: Number.parseInt([page].flat()[0] || '', 10) || 1,
    };
};

export const urlToSearchQuery = (
    search: ReadonlyURLSearchParams | string,
): SearchQuery => {
    return sanitizeSearchParams(getQueryFromSearch(search));
};

export const calculatePagination = (
    page = 1,
    size = RECIPE_SEARCH_PAGE_SIZE,
    limit = 99999,
): { start: number; size: number } => {
    // Pages are 1-based, but the API is 0-based
    const start = Math.max(page - 1, 0) * size;

    if (page * size <= limit) {
        return {
            start,
            size,
        };
    }

    return {
        start,
        size: Math.max(Math.min(page * size, limit) - start, 0),
    };
};
