import type { ComponentProps, FC } from 'react';

export const LactoseIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <title>Lactose Icon</title>
        <path d="M15.67 5H8.316l-2.323 5.808V13h2v-1.8L9.67 7h4.646l1.677 4.192v.71l-2.765 1.965a5.975 5.975 0 01-3.461 1.1H6.993a1 1 0 00-1 1V22h12V10.808zM7.993 20v-3.028h1.775a7.977 7.977 0 004.62-1.475l.026-.019a1 1 0 011.579.816V20zM15.668 3.293a1.318 1.318 0 00-1.319-1.319h-4.7a1.318 1.318 0 00-1.317 1.319v.678h7.336z" />
    </svg>
);
