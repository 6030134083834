import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';

import css from './card-body.module.scss';

type CardBodyProps = ComponentProps<'div'>;

export const CardBody: FC<CardBodyProps> = ({ className, ...restProps }) => {
    return <div className={classNames(css.body, className)} {...restProps} />;
};
