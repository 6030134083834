import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';
import css from './pagination-anchor.module.scss';

interface PaginationAnchorProps extends ComponentProps<'button'> {
    isActive?: boolean;
    page: number;
}

export const PaginationAnchor: FC<PaginationAnchorProps> = ({
    isActive = false,
    page,
    ...props
}) => {
    return (
        <button
            {...props}
            className={classNames(css.root, {
                [css.current]: isActive,
            })}
            tabIndex={0}
            type="button"
        >
            <Typography className={css.text} variant="body-regular" as="span">
                {page}
            </Typography>
        </button>
    );
};
