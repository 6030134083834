import { AllerhandeRoute } from '@enums/route';
import type { ReadonlyURLSearchParams } from 'next/navigation';

export const getQueryFromSearch = (
    search?: ReadonlyURLSearchParams | string,
): Record<string, string | string[]> => {
    const query: Record<string, string | string[]> = {};

    new URLSearchParams(search).forEach((value, key) => {
        if (Array.isArray(query[key])) {
            query[key].push(value);
        } else if (query[key]) {
            query[key] = [[query[key]].flat()[0], value];
        } else {
            query[key] = value;
        }
    });

    return query;
};

type SearchQueryParam = string | string[] | number | boolean;

export function queryToSearchString(params?: {
    [key: string]: SearchQueryParam | undefined | null;
}): string {
    if (!params) {
        return '';
    }

    const paramsString = Object.entries(params)
        .filter((item): item is [string, SearchQueryParam] => {
            const [, value] = item;
            return typeof value !== 'undefined' && value !== null;
        })
        .map(([key, value]) => {
            return Array.isArray(value)
                ? value
                      .map(
                          paramValue =>
                              `${key}=${encodeURIComponent(paramValue)}`,
                      )
                      .join('&')
                : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return paramsString ? `?${paramsString}` : '';
}

/**
 * CMS links do not contain /allerhande, so we have to add this ourself
 */
export const getCMSLinkUrl = (link: string) => {
    if (link.indexOf('recepten') === 1) {
        return `${AllerhandeRoute.Home}${link}`;
    }

    if (!link.startsWith('/') && !link.startsWith('http')) {
        return `/${link}`;
    }

    return link;
};
