import { useContext } from 'react';
import { RecipeCollectionContext } from '../../../contexts/recipe-collection-context';
import type {
    RecipeImage,
    RecipeOriginType,
} from '../../../graphql/_generated-graphql-types';

export interface UseRecipeCardHook {
    isFavoriteEnabled: boolean;
    isFavoriteLoading: boolean;
    isFavorite: boolean;
    isFavoriteChangeError: boolean;
    openFavorite: () => void;
}

export const useRecipeCard = (
    recipe: { id: number; type: RecipeOriginType; images?: RecipeImage[] },
    collectionId?: number,
): UseRecipeCardHook => {
    const recipeCollectionContext = useContext(RecipeCollectionContext);

    if (!recipeCollectionContext) {
        return {
            isFavorite: false,
            isFavoriteEnabled: false,
            isFavoriteLoading: false,
            isFavoriteChangeError: false,
            openFavorite: () => null,
        };
    }

    const isFavoriteEnabled =
        recipeCollectionContext.canFavoriteRecipes &&
        !recipeCollectionContext.isLoading;

    const collectedRecipe = Number.isInteger(collectionId)
        ? recipeCollectionContext.categories
              .find(({ id }) => id === collectionId)
              ?.list.find(({ id }) => id === recipe.id)
        : recipeCollectionContext.categories
              .flatMap(e => e.list)
              .find(({ id }) => id === recipe.id);
    const isFavorite = Boolean(collectedRecipe);

    return {
        isFavorite,
        isFavoriteEnabled,
        isFavoriteLoading: recipeCollectionContext.isChangeLoading,
        isFavoriteChangeError: recipeCollectionContext.isChangeError,
        openFavorite: () => recipeCollectionContext.handleOnOpen(recipe),
    };
};
