import type { ComponentProps, FC } from 'react';

export const VegetarianIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <title>Vegetarian Icon</title>
        <path d="M15.134 16.644a1.041 1.041 0 01-.215-1.376 6.633 6.633 0 001.008-4.323 6.4 6.4 0 00-2.6-4.469l-6.7-4.355A1.563 1.563 0 004.229 3.18L2.937 11.1l-.009.074a6.409 6.409 0 00.513 3.188 6.549 6.549 0 005.982 3.9 6.3 6.3 0 001.261-.143 18.27 18.27 0 01-1.707-1.881 4.436 4.436 0 01-.818-.157 4.542 4.542 0 01-2.888-2.527 4.351 4.351 0 01-.354-2.168l.931-5.708A1 1 0 017.38 5l4.793 3.114a4.348 4.348 0 011.767 3.063 4.635 4.635 0 01-.265 2.151 1 1 0 01-1.709.227 14.989 14.989 0 01-1.346-2.031l-.48-.878-1.755.96.48.876c.028.052.064.109.094.161.324.551.677 1.11 1.078 1.673a18.361 18.361 0 001.245 1.533h-.013a16.592 16.592 0 001.512 1.468c.269.235.564.459.854.694a31.414 31.414 0 004.081 2.382.973.973 0 001.335-.475.976.976 0 00-.481-1.282 19.233 19.233 0 01-3.436-1.992z" />
    </svg>
);
