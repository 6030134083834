import type { ComponentProps, FC } from 'react';

export const HeartIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>Heart Icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.189 16.245A33.014 33.014 0 0112 18.627c-3.2-2.096-8-6.124-8-9.51C4 6.829 5.669 4.959 7.74 5c1.202 0 2.31.672 2.976 1.81l.425.712a1 1 0 001.717 0l.431-.72c.66-1.131 1.767-1.801 2.984-1.801C18.341 4.982 20 6.829 20 9.117c0 1.647-1.136 3.443-2.669 5.097a.994.994 0 00.03 1.376 1.006 1.006 0 001.451-.037C20.573 13.646 22 11.4 22 9.117c-.001-3.401-2.59-6.127-5.738-6.115-1.465 0-2.87.636-3.896 1.718a.5.5 0 01-.734 0C10.605 3.636 9.204 3 7.752 3 4.594 2.943 2 5.718 2 9.118c0 5.37 7.889 10.56 9.471 11.545l.529.329.528-.33a35.59 35.59 0 004.078-3l-1.417-1.416z"
            fill="currentColor"
        />
    </svg>
);
