export const QueryParamKeys = {
    SEARCH: 'query',
    SORTING: 'sortBy',
    PAGINATION: 'page',
};

export const OldQueryParamKeys = {
    SEARCH: 'Ntt',
    SORTING: 'Ns',
    PAGINATION: 'Nrpp',
};

export const RECIPE_SEARCH_PAGE_SIZE = 35;
export const VIDEO_SEARCH_PAGE_SIZE = 36;
export const MY_RECIPES_SEARCH_PAGE_SIZE = 36;

export const RECIPE_SEARCH_MAX_RESULTS = 2000;

export enum SearchType {
    RECIPE = 'RECIPE',
    VIDEO = 'VIDEO',
}

export const POPULAR_FILTERS = 'veel-gebruikt';

export const FAVORITES_FILTER = 'favorieten';
