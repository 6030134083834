import type {
    RecipeImage,
    RecipeVideoImages,
} from '../../../graphql/_generated-graphql-types';

const printDimension = (
    dimension: number | undefined | null,
    unit: string,
): string => {
    return dimension ? `${dimension}${unit}` : '';
};

export const generateSrcSetString = (
    srcSet: Omit<RecipeImage, 'rendition'>[],
): string => {
    if (!Array.isArray(srcSet)) {
        return '';
    }
    return srcSet
        .filter(Boolean)
        .map(({ url, width, height }) => {
            return url
                ? `${url} ${printDimension(width, 'w')} ${printDimension(height, 'h')}`
                : '';
        })
        .join(', ');
};

export const generateRecipeImageRenditions = (
    images: Omit<RecipeImage, 'rendition'>[],
): string => {
    return generateSrcSetString(images);
};

export const generateVideoImageRenditions = (
    images: RecipeVideoImages,
): string => {
    return generateSrcSetString([{ url: images.sd, width: 900, height: 500 }]);
};
