'use client';

import {
    type RecipeSearchParams,
    RecipeSearchSortOption,
    type RecipeSearchV2Query,
    type RecipeVideoSearchParams,
    type RecipeVideoSearchQuery,
} from '@graphql/_generated-operation-types';
import type { RecipeSearchSortbySortOption } from '@helpers/search';
import type { UseSearch } from '@hooks/use-search';
import {
    SearchAction,
    type SearchMeta,
    SearchType,
    emitSearchEvent,
} from '@royalaholddelhaize/ah-analytics';
import { useEffect, useRef } from 'react';
import { v5 as uuid } from 'uuid';

export const createSearchCidMeta = ({
    recipeSearchParams,
    recipeSearchData,
    searchParams,
    searchOrigin,
}: {
    recipeSearchParams: RecipeSearchParams | RecipeVideoSearchParams;
    recipeSearchData: RecipeSearchV2Query | RecipeVideoSearchQuery;
    searchParams: Pick<UseSearch, 'query' | 'page' | 'filters' | 'sortBy'>;
    searchOrigin: string;
}): SearchMeta => {
    let filters = {};

    if (recipeSearchParams.filters) {
        filters = recipeSearchParams.filters
            .filter(e => e.values.length > 0)
            .reduce(
                (acc, filter) => ({
                    ...acc,
                    [filter.group]: filter.values,
                }),
                {},
            );
    }

    const searchTerm = recipeSearchParams.searchText ?? '';

    let recipeTitles = [''];
    let searchResultAmount = 0;
    let correctedSearchTerm: string | null | undefined;

    if ('recipeSearchV2' in recipeSearchData) {
        recipeTitles = recipeSearchData.recipeSearchV2.result.map(
            recipe => recipe.title,
        );
        searchResultAmount = recipeSearchData.recipeSearchV2.page.total;
        correctedSearchTerm =
            recipeSearchData.recipeSearchV2.correctedSearchTerm;
    }
    if ('recipeVideoSearch' in recipeSearchData) {
        recipeTitles = recipeSearchData.recipeVideoSearch.result.map(
            video => video.title,
        );
        searchResultAmount = recipeSearchData.recipeVideoSearch.page.total;
        correctedSearchTerm =
            recipeSearchData.recipeVideoSearch.correctedSearchTerm;
    }

    const metaData = {
        searchTerm,
        searchResultAmount,
        searchType: SearchType.RECIPES,
        searchOrigin,
        searchFilters: filters,
        searchSort: searchParams.sortBy ?? RecipeSearchSortOption.NEWEST,
        searchSuggestions: {
            recipes: recipeTitles,
        },
        searchTaxonomyNodes: {},
        searchQuery: correctedSearchTerm ?? searchTerm,
    };

    // We need each search to have a unique id, so we use a namespace to avoid collisions
    // We also use a hardcoded namespace to make sure the id is always the same for the same parameters
    const namespace = '64b0a8d6-a019-413e-83ed-6ded58f3445d';

    const searchId = uuid(JSON.stringify(metaData), namespace);

    return {
        ...metaData,
        searchId,
    };
};

const getQueryStringFromFilters = (filters: RecipeSearchParams['filters']) => {
    return (filters || [])
        .map(filter =>
            filter.values.map(value => `${filter.group}=${value}`).join('&'),
        )
        .join('&');
};

interface UseSearchInteractionEventProps {
    searchParams: Pick<UseSearch, 'query' | 'page' | 'filters' | 'sortBy'>;
    recipeSearchData: RecipeSearchV2Query | RecipeVideoSearchQuery | undefined;
    recipeSearchParams:
        | RecipeSearchParams
        | RecipeVideoSearchParams
        | undefined;
    lastFilterInteraction?: SearchAction;
    searchOrigin?: string;
}

export const useSearchInteractionEvent = ({
    searchParams,
    recipeSearchData,
    recipeSearchParams,
    lastFilterInteraction = SearchAction.FILTER,
    searchOrigin = 'recipe inspiration',
}: UseSearchInteractionEventProps) => {
    const filterString = getQueryStringFromFilters(searchParams.filters);
    const currentQuery = useRef<string | undefined>('');
    const currentSortBy = useRef<RecipeSearchSortbySortOption | undefined>(
        searchParams.sortBy,
    );
    const currentFilters = useRef<string>(filterString);

    // biome-ignore lint/correctness/useExhaustiveDependencies: We don't want to trigger the effect on every change
    useEffect(() => {
        if (
            currentQuery.current === searchParams.query ||
            !recipeSearchParams ||
            !recipeSearchData
        ) {
            return;
        }

        emitSearchEvent(
            createSearchCidMeta({
                recipeSearchParams,
                recipeSearchData,
                searchParams,
                searchOrigin,
            }),
            SearchAction.SEARCH,
        );
        currentQuery.current = searchParams.query;
    }, [searchParams.query]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: We don't want to trigger the effect on every change
    useEffect(() => {
        if (
            currentSortBy.current === searchParams.sortBy ||
            !searchParams.sortBy ||
            !recipeSearchParams ||
            !recipeSearchData
        ) {
            return;
        }

        emitSearchEvent(
            createSearchCidMeta({
                recipeSearchParams,
                recipeSearchData,
                searchParams,
                searchOrigin,
            }),
            SearchAction.SORT,
        );
        currentSortBy.current = searchParams.sortBy;
    }, [searchParams.sortBy]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: We don't want to trigger the effect on every change
    useEffect(() => {
        if (
            currentFilters.current === filterString ||
            !recipeSearchParams ||
            !recipeSearchData
        ) {
            return;
        }

        emitSearchEvent(
            createSearchCidMeta({
                recipeSearchParams,
                recipeSearchData,
                searchParams,
                searchOrigin,
            }),
            lastFilterInteraction,
        );
        currentSortBy.current = searchParams.sortBy;
    }, [filterString]);
};
