import type { Locale } from '@royalaholddelhaize/ah-web-core';
import type { RecipeSummary } from '../../../graphql/_generated-graphql-types';
import type { RecipeCardRecipe } from '../interfaces/recipe-card';
import { getCardTranslations } from './translations';

export const ariaTitle = (recipe: RecipeCardRecipe, locale: Locale): string => {
    const { t } = getCardTranslations(locale);
    let builder = `${recipe.title}`;

    const time = 'time' in recipe ? recipe.time.cook : recipe.cookTime;
    if (time) {
        builder = builder.concat(', ', t('aria.cooktime', { time }));
    }

    const nutritions =
        'nutritions' in recipe
            ? recipe.nutritions
            : (recipe as RecipeSummary).nutrition;
    if (nutritions?.energy) {
        builder = builder.concat(
            ', ',
            `${nutritions.energy.value} ${nutritions.energy.unit}`,
        );
    }

    const classifications =
        'classifications' in recipe ? recipe.classifications : [];
    if (classifications) {
        let label: string | undefined;

        if (classifications.includes('vegetarisch')) {
            label = t('vegetarisch');
        }

        if (classifications.includes('veganistisch')) {
            label = t('veganistisch');
        }

        if (classifications.includes('lactosevrij')) {
            label = t('lactosevrij');
        }

        if (classifications.includes('glutenvrij')) {
            label = t('glutenvrij');
        }

        if (label) {
            builder = builder.concat(', ', label);
        }
    }

    if (
        recipe.rating &&
        Boolean(recipe.rating.count && recipe.rating.average)
    ) {
        builder = builder.concat(
            ', ',
            t('aria.rating', { rating: recipe.rating.average || 0 }),
        );
    }

    const servings = 'servings' in recipe ? recipe.servings : recipe.serving;
    if (servings) {
        builder = builder.concat(
            ', ',
            t('aria.servingSize', {
                number: servings.number,
                type: servings.type,
            }),
        );
    }

    return builder;
};
