import type { ComponentProps, FC } from 'react';

export const TimeIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <title>Time Icon</title>
        <path d="M13 10V6h-2v5a2 2 0 002 2h4v-2h-3a1 1 0 01-1-1zM17.92 6.6a1 1 0 001.26 0 1 1 0 000-1.57 1 1 0 00-1.55.33 1 1 0 00.26 1.21zM12.35 3.82a1 1 0 001.26 0 1 1 0 000-1.57 1 1 0 00-1.55.33 1 1 0 00.26 1.2zM15.45 4.76a1 1 0 001.26 0 1 1 0 000-1.57 1 1 0 00-1.55.33 1 1 0 00.25 1.21z" />
        <path d="M21.89 10.57l-1.95.5c0 .31.06.62.06.93a8 8 0 11-9-7.93v-2A10 10 0 1022 12a9.63 9.63 0 00-.11-1.43z" />
        <path d="M19.54 9.23a1 1 0 001.26 0 1 1 0 000-1.57 1 1 0 00-1.51.34 1 1 0 00.25 1.23z" />
    </svg>
);
