import { Option24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/option-24';
import classNames from 'clsx';
import type { ComponentProps } from 'react';

import css from './pagination-elipsis.module.scss';

export const PaginationEllipsis = ({
    className,
    ...props
}: ComponentProps<'span'>) => (
    <span aria-hidden {...props} className={classNames(css.root, className)}>
        <Option24Icon size={16} />
    </span>
);
