import {
    type RecipeAuthor,
    RecipeOriginType,
} from '../../../graphql/_generated-graphql-types';

export const getRecipeHref = ({
    recipeId,
    slug,
    author,
    host = '',
    basePath = '/allerhande',
}: {
    recipeId: number;
    slug: string;
    author?: RecipeAuthor | null;
    host?: string;
    // TODO Delete custom basePath after migration
    basePath?: string;
}): string => {
    switch (author?.origin?.type) {
        case RecipeOriginType.MEMBER_SCRAPED:
            return author.origin.url || '';
        case RecipeOriginType.MEMBER_CREATED:
            return `${host}${basePath}/mijn-recept/R-R${recipeId}/${slug}`;
        default:
            return `${host}${basePath}/recept/R-R${recipeId}/${slug}`;
    }
};
