import type { ComponentProps, FC } from 'react';

export const CaloriesIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <title>Calories Icon</title>
        <path d="M17.379 7.678l-1.634-1.136.064 1.989a3.764 3.764 0 01-.777 2.7.512.512 0 01-.427.187c-.59-.05-.749-.284-.8-.361a2.672 2.672 0 01-.069-1.813c.706-1.886 1.409-5.892-.983-7.561l-.678-.473-.592.577s-4.288 4.178-4.9 4.783A11.568 11.568 0 003.13 12.5a8.547 8.547 0 00.7 5.273A9.343 9.343 0 0012 23.032a9.392 9.392 0 007.978-4.582 9.032 9.032 0 001.041-4 7.592 7.592 0 00-3.64-6.772zM12.539 21c-.18.014-.36.037-.541.037-.155 0-.312-.026-.468-.038a3.36 3.36 0 01-.522-1.6 2.6 2.6 0 011.016-2.014 2.528 2.528 0 01.961 1.979A4.066 4.066 0 0112.539 21zm5.678-3.5a7.1 7.1 0 01-3.366 2.925 4.9 4.9 0 00.134-1.063 4.618 4.618 0 00-2.475-4.055l-.484-.268-.484.268A4.742 4.742 0 009.008 19.4a4.3 4.3 0 00.127.952 7.407 7.407 0 01-3.5-3.43 6.546 6.546 0 01-.535-4.054 9.666 9.666 0 012.91-4.892c.437-.436 2.851-2.789 4.108-4.016a6.86 6.86 0 01-.269 4.631 4.587 4.587 0 00.3 3.575 2.9 2.9 0 002.3 1.241 2.512 2.512 0 001.971-.728 4.544 4.544 0 001.192-2.2 5.338 5.338 0 011.417 3.937 7 7 0 01-.812 3.084z" />
    </svg>
);
