import {
    RegularAnchor,
    RegularAnchorText,
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import type { RegularButtonProps } from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button.interfaces';
import classNames from 'clsx';
import type { ComponentProps, FC, PropsWithChildren } from 'react';
import { LinkOrAnchor } from '../link-or-anchor/link-or-anchor';

import css from './button.module.scss';

export interface ButtonProps
    extends RegularButtonProps,
        ComponentProps<'button'> {
    variant?: 'primary' | 'secondary';
    disabled?: boolean;
}

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
    className,
    children,
    variant = 'primary',
    level = 'primary',
    disabled = false,
    ...props
}) => {
    return (
        <RegularButton
            className={classNames(
                css.root,
                {
                    [css.primary]:
                        variant === 'secondary' && level === 'primary',
                    [css.secondary]:
                        variant === 'secondary' && level === 'secondary',
                    [css.disabled]: disabled,
                },
                className,
            )}
            aria-disabled={disabled}
            level={level}
            {...props}
        >
            {children}
        </RegularButton>
    );
};

export const ButtonText = RegularButtonText;

type AnchorButtonProps = ButtonProps & ComponentProps<'a'>;

export const AnchorButton: FC<PropsWithChildren<AnchorButtonProps>> = ({
    className,
    children,
    variant = 'primary',
    level = 'primary',
    disabled = false,
    ...props
}) => {
    return (
        <RegularAnchor
            className={classNames(
                css.root,
                {
                    [css.primary]:
                        variant === 'secondary' && level === 'primary',
                    [css.secondary]:
                        variant === 'secondary' && level === 'secondary',
                    [css.disabled]: disabled,
                },
                className,
            )}
            aria-disabled={disabled}
            level={level}
            as={LinkOrAnchor}
            {...props}
        >
            {children}
        </RegularAnchor>
    );
};

export const AnchorButtonText = RegularAnchorText;
