import type { ComponentProps, FC } from 'react';

export const HeartFilledIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>Heart Filled Icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20.995l-.529-.33C9.89 19.682 2 14.495 2 9.122c0-3.4 2.571-6.13 5.752-6.116 1.453 0 2.855.636 3.883 1.722a.493.493 0 00.727 0c1.047-1.104 2.49-1.748 3.978-1.721 3.114 0 5.66 2.743 5.66 6.115 0 5.37-7.89 10.56-9.471 11.545l-.529.329z"
            fill="currentColor"
        />
    </svg>
);
