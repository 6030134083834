import type {
    RecipeSearchFilterFragment,
    RecipeSearchQueryFilter,
} from '@graphql/_generated-operation-types';

export const updateFilters = (
    filters: RecipeSearchQueryFilter[],
    changedFilters: RecipeSearchFilterFragment[],
) => {
    let newFilters: RecipeSearchQueryFilter[] = [...filters];

    changedFilters.forEach(changedFilter => {
        const oldFilter = newFilters.find(
            filter => filter.group === changedFilter.group,
        );
        if (changedFilter.selected) {
            // if there is existing filter group just add the key
            if (oldFilter) {
                if (oldFilter.values.includes(changedFilter.name)) {
                    return;
                }
                oldFilter.values.push(changedFilter.name);
            } else {
                // add a filter group with a new key if there is no existing one
                newFilters.push({
                    group: changedFilter.group,
                    values: [changedFilter.name],
                });
            }
            // remove a key from the existing group when there are more than 1 key
        } else if (oldFilter && oldFilter.values.length > 1) {
            oldFilter.values = oldFilter.values.filter(
                key => key !== changedFilter.name,
            );
        } else {
            // remove a group if there is just one key in it
            newFilters = newFilters.filter(
                filter => filter.group !== changedFilter.group,
            );
        }
    });

    return newFilters;
};
