import type { ComponentProps, FC } from 'react';

export const FavoriteIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <title>Favorite Icon</title>
        <path d="M17.553 20.9a1.193 1.193 0 01-.7-.226l-4.834-3.5L7.2 20.665a1.19 1.19 0 01-1.829-1.333l1.849-5.675-4.838-3.506A1.19 1.19 0 013.081 8h5.971l1.841-5.675a1.186 1.186 0 011.131-.825 1.185 1.185 0 011.13.824L15 8h5.968a1.19 1.19 0 01.7 2.151l-4.821 3.5 1.837 5.691a1.191 1.191 0 01-1.131 1.558zm-11.53-1.854zm6-4.35l4 2.9-1.523-4.714L18.471 10h-4.925l-1.522-4.685L10.506 10H5.577l3.992 2.9-1.528 4.683zM20.966 10zm-9.712-7.06z" />
    </svg>
);
