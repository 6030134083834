'use client';

import {
    emitInitializeFavoriteRecipes,
    emitSelectRecipe,
    recipeBatchService,
} from '@royalaholddelhaize/ah-analytics';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    FAVORITE_BUTTON,
    RECIPE_CARD,
    RECIPE_CARD_PROPERTIES,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-ui-recipe-card';
import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { VisuallyHidden } from '@royalaholddelhaize/design-system-pantry-web/components/utilities/visually-hidden/visually-hidden';
import { useInView } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-in-view/use-in-view';
import { type FC, type MouseEvent, useRef } from 'react';
import { CaloriesIcon } from '../../../assets/calories';
import { ExternalIcon } from '../../../assets/external';
import { FavoriteIcon } from '../../../assets/favorite';
import { GlutenIcon } from '../../../assets/gluten';
import { HeartIcon } from '../../../assets/heart';
import { HeartFilledIcon } from '../../../assets/heart-filled';
import { LactoseIcon } from '../../../assets/lactose';
import { PersonIcon } from '../../../assets/person';
import { TimeIcon } from '../../../assets/time';
import { VeganIcon } from '../../../assets/vegan';
import { VegetarianIcon } from '../../../assets/vegetarian';
import {
    RecipeOriginType,
    type RecipeSummary,
} from '../../../graphql/_generated-graphql-types';
import { useRecipeCard } from '../hooks/use-recipe-card';
import type { RecipeCardRecipe } from '../interfaces/recipe-card';
import { ariaTitle } from '../utils/aria-title';
import { getRecipeHref } from '../utils/href';
import { generateRecipeImageRenditions } from '../utils/image-renditions';
import { getCardTranslations } from '../utils/translations';
import { CardBody } from './elements/card-body/card-body';
import { CardContent } from './elements/card-content/card-content';
import { CardFloatingIconCTA } from './elements/card-floating-icon-cta/card-floating-icon-cta';
import { CardImage } from './elements/card-image/card-image';
import {
    CardProperties,
    CardProperty,
} from './elements/card-properties/card-properties';
import { CardText } from './elements/card-text/card-text';
import { Card, type CardProps } from './elements/card/card';

export type RecipeCardProps = {
    recipe: RecipeCardRecipe;
    locale: Locale;
    collectionId?: number;
    cid?: {
        index?: number;
        listType: string;
        listName: string;
    };
    basePath?: string;
} & CardProps;

export const RecipeCard: FC<RecipeCardProps> = ({
    recipe,
    locale,
    collectionId,
    cid,
    basePath,
    onClick,
    ...restProps
}) => {
    const { isFavorite, isFavoriteEnabled, openFavorite } = useRecipeCard(
        {
            id: recipe.id,
            type: recipe.author?.origin?.type || RecipeOriginType.ALLERHANDE,
            images: recipe.images || [],
        },
        collectionId,
    );
    const { t } = getCardTranslations(locale);
    const ref = useRef<HTMLAnchorElement>(null);

    useInView({
        ref,
        once: true,
        callback: isInView => {
            if (isInView) {
                recipeBatchService.load({
                    ...recipe,
                    index: cid?.index,
                    listType: cid?.listType,
                    listName: cid?.listName,
                });
            }
        },
    });

    const slug = 'slug' in recipe ? recipe.slug : recipe.slugifiedTitle;
    const time = 'time' in recipe ? recipe.time.cook : recipe.cookTime;
    const nutritions =
        'nutritions' in recipe
            ? recipe.nutritions
            : (recipe as RecipeSummary).nutrition;
    const classifications =
        'classifications' in recipe ? recipe.classifications : recipe.diet;
    const servings = 'servings' in recipe ? recipe.servings : recipe.serving;

    const isExternalRecipe =
        recipe.author?.origin?.type === RecipeOriginType.MEMBER_SCRAPED;
    const href = getRecipeHref({
        recipeId: recipe.id,
        slug,
        author: recipe.author,
        basePath,
    });

    let label = null;
    if (classifications?.includes('vegetarisch')) {
        label = (
            <>
                <VegetarianIcon width={16} height={16} aria-hidden />

                {t('vegetarisch')}
            </>
        );
    }

    if (classifications?.includes('veganistisch')) {
        label = (
            <>
                <VeganIcon width={16} height={16} aria-hidden />

                {t('veganistisch')}
            </>
        );
    }

    if (classifications?.includes('lactosevrij')) {
        label = (
            <>
                <LactoseIcon width={16} height={16} aria-hidden />

                {t('lactosevrij')}
            </>
        );
    }

    if (classifications?.includes('glutenvrij')) {
        label = (
            <>
                <GlutenIcon width={16} height={16} aria-hidden />

                {t('glutenvrij')}
            </>
        );
    }

    const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
        if (cid) {
            emitSelectRecipe({
                ...recipe,
                index: cid.index || 0,
                listType: cid.listType,
                listName: cid.listName,
            });
        }

        onClick && onClick(e);
    };

    const handleOnAddToCollectionClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (cid) {
            emitInitializeFavoriteRecipes({
                ...recipe,
                listName: cid.listName,
            });
        }

        openFavorite();
    };

    return (
        <Card
            ref={ref}
            href={href}
            title={`Recept: ${recipe.title}`}
            aria-label={`Recept: ${ariaTitle(recipe, locale)}`}
            target={isExternalRecipe ? '_blank' : '_self'}
            onClick={handleOnClick}
            {...createTestHook(RECIPE_CARD)}
            {...restProps}
        >
            <CardImage
                alt={recipe.title}
                srcSet={generateRecipeImageRenditions(recipe.images)}
            />

            <CardBody>
                <CardProperties {...createTestHook(RECIPE_CARD_PROPERTIES)}>
                    {recipe.author?.origin?.type &&
                        [
                            RecipeOriginType.MEMBER_CREATED,
                            RecipeOriginType.MEMBER_SCRAPED,
                        ].includes(recipe.author.origin.type) && (
                            <CardProperty>
                                {recipe.author?.origin?.type ===
                                    RecipeOriginType.MEMBER_CREATED &&
                                    t('myRecipe')}

                                {recipe.author?.origin?.type ===
                                    RecipeOriginType.MEMBER_SCRAPED && (
                                    <>
                                        <ExternalIcon
                                            width={16}
                                            height={16}
                                            aria-hidden
                                        />

                                        {recipe.author.origin.hostName?.replace(
                                            'www.',
                                            '',
                                        ) || t('externalRecipe')}
                                    </>
                                )}
                            </CardProperty>
                        )}

                    {recipe.author?.origin?.type !==
                        RecipeOriginType.MEMBER_SCRAPED && (
                        <>
                            {Boolean(time) && (
                                <CardProperty>
                                    <TimeIcon
                                        width={16}
                                        height={16}
                                        aria-hidden
                                    />

                                    <span aria-hidden>{time} min</span>
                                    <VisuallyHidden>
                                        {t('aria.cooktime', { time })}
                                    </VisuallyHidden>
                                </CardProperty>
                            )}

                            {nutritions?.energy && (
                                <CardProperty>
                                    <CaloriesIcon
                                        width={16}
                                        height={16}
                                        aria-hidden
                                    />

                                    {`${nutritions.energy.value} ${nutritions.energy.unit}`}
                                </CardProperty>
                            )}

                            {Boolean(label) && (
                                <CardProperty>{label}</CardProperty>
                            )}

                            {recipe.rating &&
                                Boolean(
                                    recipe.rating.count &&
                                        recipe.rating.average,
                                ) && (
                                    <CardProperty>
                                        <FavoriteIcon
                                            width={16}
                                            height={16}
                                            aria-hidden
                                        />

                                        <span aria-hidden>
                                            {Math.round(
                                                recipe.rating.average || 0,
                                            )}
                                        </span>
                                        <VisuallyHidden>
                                            {t('aria.rating', {
                                                rating: Math.round(
                                                    recipe.rating.average || 0,
                                                ),
                                            })}
                                        </VisuallyHidden>
                                    </CardProperty>
                                )}

                            {servings && (
                                <CardProperty>
                                    <PersonIcon
                                        width={16}
                                        height={16}
                                        aria-hidden
                                    />

                                    <span aria-hidden>{servings.number}</span>
                                    <VisuallyHidden>
                                        {t('aria.servingSize', {
                                            number: servings.number,
                                            type: servings.type,
                                        })}
                                    </VisuallyHidden>
                                </CardProperty>
                            )}
                        </>
                    )}
                </CardProperties>

                <CardContent>
                    <CardText>{recipe.title}</CardText>
                </CardContent>
            </CardBody>

            {isFavoriteEnabled && (
                <CardFloatingIconCTA
                    selected={isFavorite}
                    onClick={handleOnAddToCollectionClick}
                    aria-label={
                        isFavorite ? t('editFavorite') : t('addFavorite')
                    }
                    {...createTestHook(FAVORITE_BUTTON)}
                >
                    {isFavorite ? (
                        <HeartFilledIcon aria-hidden />
                    ) : (
                        <HeartIcon aria-hidden />
                    )}
                </CardFloatingIconCTA>
            )}
        </Card>
    );
};
