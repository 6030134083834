import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';

import css from './card.module.scss';

export type CardProps = ComponentProps<'a'> & {
    as?: FC<ComponentProps<'a'>>;
};

export const Card: FC<CardProps> = ({
    className,
    // ? Is there a better way?
    as: Tag = ({ ...props }) => <a {...props} />,
    ...restProps
}) => {
    return <Tag className={classNames(css.root, className)} {...restProps} />;
};
