'use client';

import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { Cookie } from '../../enums/cookie';
import { Viewport } from '../../enums/viewport';
import type { ViewportData } from '../types';
import { ViewportContext } from './context';

interface ViewportProviderProps extends PropsWithChildren {
    data: ViewportData;
}

export const ViewportProvider: FC<ViewportProviderProps> = ({
    data,
    children,
}) => {
    const [viewport, setViewport] = useState<Viewport>(data.viewport);

    const handleOnResize = () => {
        const innerWidth = window.innerWidth;

        if (innerWidth <= 359) {
            setViewport(Viewport.EXTRA_SMALL);
        }

        if (innerWidth >= 360 && innerWidth <= 699) {
            setViewport(Viewport.SMALL);
        }

        if (innerWidth >= 700 && innerWidth <= 999) {
            setViewport(Viewport.MEDIUM);
        }

        if (innerWidth >= 1000 && innerWidth <= 1347) {
            setViewport(Viewport.LARGE);
        }

        if (innerWidth >= 1348) {
            setViewport(Viewport.EXTRA_LARGE);
        }
    };

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (window) {
            // Check size on first load
            handleOnResize();

            // Check size on every resize
            window.addEventListener('resize', handleOnResize);
        }

        return () => window.removeEventListener('resize', handleOnResize);
    }, []);

    useEffect(() => {
        // Update cookie when viewport changes
        document.cookie = `${Cookie.VIEWPORT}=${viewport}; path=/`;
    }, [viewport]);

    return (
        <ViewportContext.Provider value={{ viewport }}>
            {children}
        </ViewportContext.Provider>
    );
};
