import type { ComponentProps, FC } from 'react';

export const ExternalIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <title>External Icon</title>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 3C2 2.44772 2.44772 2 3 2H7C7.55228 2 8 2.44772 8 3C8 3.55228 7.55228 4 7 4H4V20H20V15.7C20 15.1477 20.4477 14.7 21 14.7C21.5523 14.7 22 15.1477 22 15.7V21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21V3ZM14.3 3C14.3 2.44772 14.7478 2 15.3 2H21C21.5523 2 22 2.44772 22 3V8.6C22 9.15229 21.5523 9.6 21 9.6C20.4478 9.6 20 9.15229 20 8.6V5.3289L11.8108 13.6034C11.4223 13.996 10.7891 13.9993 10.3966 13.6108C10.0041 13.2223 10.0008 12.5891 10.3893 12.1966L18.5014 4H15.3C14.7478 4 14.3 3.55228 14.3 3Z"
        />
    </svg>
);
