import type { ComponentProps, FC } from 'react';

export const GlutenIcon: FC<ComponentProps<'svg'>> = ({ ...props }) => (
    <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <title>Gluten Icon</title>
        <path d="M16.466 2.347a1 1 0 00-1 1V3.5a3 3 0 01-2 2.816V1h-2v5.316a3 3 0 01-2-2.816v-.153a1 1 0 00-1-1 1 1 0 00-1 1V11.5a5.009 5.009 0 004 4.9V21h-1a1 1 0 00-1 1 1 1 0 001 1h4a1 1 0 001-1 1 1 0 00-1-1h-1v-4.6a5.009 5.009 0 004-4.9V3.347a1 1 0 00-1-1zm-7 5.127a4.954 4.954 0 002 .925v1.917a3 3 0 01-2-2.816zm0 4.026v-.026a4.954 4.954 0 002 .925v1.917a3 3 0 01-2-2.816zm4 2.816V12.4a4.947 4.947 0 002-.925v.025a3 3 0 01-2 2.816zm0-4V8.4a4.947 4.947 0 002-.925V7.5a3 3 0 01-2 2.816z" />
    </svg>
);
