'use client';

import SearchIcon from '@assets/icons/search.svg';
import { Button } from '@components/elements/button/button';
import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import {
    SEARCH_BUTTON,
    SEARCH_INPUT,
} from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import type { IDValuePair } from '@royalaholddelhaize/design-system-pantry-web/components/form/form.interfaces';
import {
    InputField,
    InputFieldGroup,
} from '@royalaholddelhaize/design-system-pantry-web/components/form/input/field/input-field';
import type { InputProps } from '@royalaholddelhaize/design-system-pantry-web/components/form/input/input.interfaces';
import classNames from 'clsx';
import { type ComponentProps, type FC, useCallback } from 'react';

import css from './search-input.module.scss';

export type SearchInputProps = {
    variant?: 'default' | 'big';
    onClear?: () => void;
} & InputProps &
    ComponentProps<'input'>;

export const SearchInput: FC<SearchInputProps> = ({
    variant = 'default',
    onClear,
    onChangeValue,
    className,
    ...props
}) => {
    const { t } = useTranslations();
    const iconSize = variant === 'big' ? 27 : 19;

    const handleClear = useCallback(
        (pair: IDValuePair) => {
            const { value } = pair;
            if (!value && onClear) {
                onClear();
            }

            if (onChangeValue) {
                onChangeValue(pair);
            }
        },
        [onChangeValue, onClear],
    );

    return (
        <div
            className={classNames(
                css.root,
                variant === 'big' && css.big,
                className,
            )}
        >
            <InputFieldGroup className={css.inputGroup}>
                <InputField
                    {...props}
                    id="search-input"
                    className={css.input}
                    type="search"
                    onChangeValue={handleClear}
                    {...createTestHook(SEARCH_INPUT)}
                />
            </InputFieldGroup>
            <Button
                type="submit"
                aria-label={t('search')}
                className={css.button}
                {...createTestHook(SEARCH_BUTTON)}
            >
                <SearchIcon
                    className={css.icon}
                    width={iconSize}
                    height={iconSize}
                />
            </Button>
        </div>
    );
};
