import classNames from 'clsx';
import type { ComponentProps, FC } from 'react';

import css from './card-content.module.scss';

type CardContentProps = ComponentProps<'div'>;

export const CardContent: FC<CardContentProps> = ({
    className,
    ...restProps
}) => {
    return (
        <div className={classNames(css.content, className)} {...restProps} />
    );
};
