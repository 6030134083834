import { Button, type ButtonProps } from '@components/elements/button/button';
import { createTestHook } from '@royalaholddelhaize/ah-test-hooks';
import { SEARCH_PAGINATION_PREVIOUS } from '@royalaholddelhaize/ah-test-hooks/hooks/ah-allerhande';
import { ChevronLeft16Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah/chevron-left-16';
import type { FC } from 'react';

export const PaginationPreviousButton: FC<ButtonProps> = props => {
    return (
        <Button
            {...createTestHook(SEARCH_PAGINATION_PREVIOUS)}
            {...props}
            variant="secondary"
        >
            <ChevronLeft16Icon size={12} />
        </Button>
    );
};
