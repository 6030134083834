import {
    getCorrectDictionary,
    translationsCore,
} from '@royalaholddelhaize/ah-i18n';
import { Locale } from '@royalaholddelhaize/ah-web-core';
import enUS from '../locales/en-US.json';
import nlBE from '../locales/nl-BE.json';
import nlNL from '../locales/nl-NL.json';

const dictionaries = {
    [Locale.en_US]: enUS,
    [Locale.nl_BE]: nlBE,
    [Locale.nl_NL]: nlNL,
};

export const getCardTranslations = (locale: Locale) => {
    const dictionary = getCorrectDictionary(dictionaries, locale);

    return translationsCore({
        locale,
        dictionary,
        locales: [Locale.nl_NL, Locale.nl_BE, Locale.en_US],
    });
};
